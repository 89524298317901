$primary: #ffcd38;
$secondary: #e0650e;
$green: #1e5a50;
$white: #f4f3f2;
$hover-green: #cef0ea;
$bg: #fafafa;
$txt: #000;
$txtRevert: #000;
$altTxt: #f7f1e1;
$gray: #000;
